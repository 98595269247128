
footer {
	position: absolute;
	width: 100%;
	bottom: 0;
	height: var(--footer-height);
	padding: 0 var(--hori-padding);
}

.social {
	margin: 0;
	padding: 0;
}

.social li{
	width: 1.2em;
	display: inline-block;
	margin-inline-end: 0.3em;
}

.copyright {
    color: var(--text-color);
	font-size: 0.5em;
	font-family: 'Roboto Mono', monospace;
}

.copyright strong {
	font-size: 1.5em;
}